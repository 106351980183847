import { SYMBOLS_FILE } from "../api-config.mjs";
import log from 'loglevel';
import axios from 'axios';

let urls = {};

const processAllSymbols = (allSymbols) => {
  const exchanges = {};
  for (let symbol of allSymbols) {
    if (!(symbol.exchange in exchanges)) {
      exchanges[symbol.exchange] = {};
    }
    exchanges[symbol.exchange][symbol.symbol] = symbol;
  }
  return { exchanges, allSymbols };
}

const loadSymbolsFromJson = async (url) => {
  // Uncomment the below for testing, to introduce an arbitrary delay before loading symbols
  // const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
  // log.debug("Waiting for 2 seconds...");
  // await waitFor(200000);
  // log.debug("Done waiting!");
  log.debug("Loading Symbols:", url);
  const response = await axios.get(url);
  // log.debug("response:", response);
  const allSymbols = response.data;
  // log.debug("allSymbols:", _allSymbol);
  return processAllSymbols(allSymbols);
};

// WARNING: Add new indexes to the *END* of this list, so that older indexes remain valid
const EXCHANGES_IN_ORDER = ["NYSE", "NASDAQ", "AMEX", "TSX", "TSXV", "OTC", "LSE", "CBOE"];

const indexToExchangeName = (_exchangeIndex) => {
  const exchangeIndex = parseInt(_exchangeIndex);
  if (exchangeIndex === -1) {
    return "UNKNOWN";
  }
  return EXCHANGES_IN_ORDER[exchangeIndex];
};

const loadSymbolsFromTxt = async (url) => {
  // Uncomment the below for testing, to introduce an arbitrary delay before loading symbols
  // const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
  // log.debug("Waiting for 2 seconds...");
  // await waitFor(200000);
  // log.debug("Done waiting!");
  log.debug("Loading Symbols:", url);
  const response = await axios.get(url);
  const rawData = response.data;
  const allSymbols = rawData.split("\n").map(line => {
    const [ exchangeIndexAsString, symbol, name ] = line.split("\t");
    const exchange = indexToExchangeName(exchangeIndexAsString);
    return { exchange, symbol, name };
  }).filter(({ exchange, symbol, name }) => exchange && symbol && name);
  return processAllSymbols(allSymbols);
};

const getUrl = (config) => {
  const { endpoint = "", symbolsFile = SYMBOLS_FILE } = config;
  return `${endpoint}/${symbolsFile}`;
};

const makeSymbolRequest = async (config) => {
  const { rawDataFormat = "txt" } = config;
  const url = getUrl(config);
  if (!urls[url]) {
    if (rawDataFormat === "json") {
      urls[url] = loadSymbolsFromJson(url);
    } else if (rawDataFormat === "txt") {
      urls[url] = loadSymbolsFromTxt(url);
    } else {
      throw new Error(`Symbols rawDataFormat: ${rawDataFormat} not supported`);
    }
  }
};

const getSymbol = async (exchange, symbol, config = {}) => {
  makeSymbolRequest(config);
  const { exchanges } = await urls[getUrl(config)];
  const _exchange = exchange.toUpperCase();
  const _symbol = symbol.toUpperCase();
  if (!(_exchange in exchanges) || !(_symbol in exchanges[_exchange])) {
    return null;
  } else {
    return exchanges[_exchange][_symbol];
  }
};

const isAllSymbolsLoaded = (config = {}) => {
  const url = getUrl(config);
  return url in urls;
};

const getAllSymbols = async (config = {}) => {
  makeSymbolRequest(config);
  const { allSymbols } = await urls[getUrl(config)];
  return allSymbols;
};

const getCompanyName = async (exchange, symbol) => {
  log.debug("Exchange _>", exchange, symbol);
  let _sym = await getSymbol(exchange, symbol);
  log.debug("_sym", _sym);
  const { name = null } = _sym || {};
  return name;
};

export {
  getSymbol,
  getAllSymbols,
  getCompanyName,
  isAllSymbolsLoaded,
};
